import {
  Accordion,
  useMantineTheme,
  Container,
  Group,
  Box,
  MediaQuery
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

import { useStyles } from './Footer.style';

import { PartialWrapper } from '@/components/PartialWrapper';

export interface FooterProps {
  urlSetOne: any[];
  urlSetTwo: any[];
  urlSetThree: any[];
  urlSetLegal: any[];
  urlSetFour: any[];
  urlSetFive: any[];
  needHelp: any[];
  urlSocialMedia: any[];
}

export const Footer = (props: FooterProps): React.ReactElement => {
  const theme = useMantineTheme();
  const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);
  const currentYear = new Date().getFullYear();
  const largeScreen = useMediaQuery(`(min-width: ${theme.breakpoints.xs})`);
  const desktop = useMediaQuery(`(min-width: ${theme.breakpoints.lg})`);

  const {
    urlSetOne,
    urlSetTwo,
    urlSetThree,
    urlSetLegal,
    urlSetFive,
    needHelp,
    urlSocialMedia
  } = props;

  const { classes } = useStyles();
  const renderDownloadAppContent = (): React.ReactElement => (
    <ul id="app-links-footer" className={classes.footerAppLink}>
      <MediaQuery smallerThan="sm" styles={{ display: 'none' }}>
        <li className={classes.appDescription}>
          Scan the code to get app-exclusive offers!
        </li>
      </MediaQuery>
      {!desktop
        ? (
        <>
          <li>
            <a href="https://apps.apple.com/au/app/mydeal-shop-for-the-home/id1512739634">
              <img
                className="apple-appstore-badge"
                src="/content/images/app/appleappstorebadge.svg"
              />
            </a>
          </li>
          <li>
            <a href="https://play.google.com/store/apps/details?id=com.appetiser.mydeal">
              <img
                className="google-playstore-badge"
                src="/content/images/app/google-play-badge.png"
                alt="Get it on Google Play"
              />
            </a>
          </li>
        </>
          )
        : (
            ''
          )}

      <li>
        <a href="/mydeal-mobile-app">Learn More</a>
      </li>
      {!mobile
        ? (
        <li>
          <img
            className="app-qr-code"
            src="/content/images/app_links_qr_code_footer.png"
            alt="MyDeal App QR Code"
          />
        </li>
          )
        : (
            ''
          )}
    </ul>
  );

  const renderNeedHelpButton = (): React.ReactElement => (
    <a
      className="btn primary contact"
      href={needHelp[0].url}
      title={needHelp[0].label}
    >
      {needHelp[0].label}
    </a>
  );

  return (
    <>
      <footer>
        {mobile
          ? (
          <>
            <Accordion
              className={classes.footerMobileAccordion}
              classNames={{
                item: classes.accordionItem,
                itemTitle: classes.itemTitle,
                label: classes.accordionLabel,
                chevron: classes.chevron,
                control: classes.accordionControl,
                panel: classes.accordionPanel
              }}
              radius="xs"
              chevronSize="30px"
              transitionDuration={1000}
              defaultValue="download-app"
            >
              <Accordion.Item value="company">
                <Accordion.Control>Company</Accordion.Control>
                <Accordion.Panel>
                  <ul>
                    {urlSetOne.map((item, index) => (
                      <li key={index}>
                        <a
                          href={item.url}
                          target={item.target ? item.target : '_self'}
                        >
                          {item.label}
                        </a>
                      </li>
                    ))}
                  </ul>
                </Accordion.Panel>
              </Accordion.Item>
              <Accordion.Item value="account">
                <Accordion.Control>Account</Accordion.Control>
                <Accordion.Panel>
                  <ul>
                    {urlSetTwo.map((item, index) => (
                      <li key={index}>
                        <a
                          href={item.url}
                          target={item.target ? item.target : '_self'}
                        >
                          {item.label}
                        </a>
                      </li>
                    ))}
                  </ul>
                </Accordion.Panel>
              </Accordion.Item>
              <Accordion.Item value="legal">
                <Accordion.Control>Legal</Accordion.Control>
                <Accordion.Panel>
                  <ul>
                    {urlSetLegal.map((item, index) => (
                      <li key={index}>
                        <a
                          href={item.url}
                          target={item.target ? item.target : '_self'}
                        >
                          {item.label}
                        </a>
                      </li>
                    ))}
                  </ul>
                </Accordion.Panel>
              </Accordion.Item>
              <Accordion.Item value="popular-categories">
                <Accordion.Control>Popular Categories</Accordion.Control>
                <Accordion.Panel>
                  <ul>
                    {urlSetThree.map((item, index) => (
                      <li key={index}>
                        <a
                          href={item.url}
                          target={item.target ? item.target : '_self'}
                        >
                          {item.label}
                        </a>
                      </li>
                    ))}
                  </ul>
                </Accordion.Panel>
              </Accordion.Item>
              <Accordion.Item value="download-app">
                <Accordion.Control>Download App</Accordion.Control>
                <Accordion.Panel>
                  {renderDownloadAppContent()}
                </Accordion.Panel>
              </Accordion.Item>
            </Accordion>
            <div className="">
              <div className="footerheaders exceptional">
                Customer Service
              </div>
              {renderNeedHelpButton()}
              <ul className="customerServiceNewLinks">
                {urlSetFive.map((item, index) => (
                  <li key={index}>
                    <a
                      href={item.url}
                      target={item.target ? item.target : '_self'}
                    >
                      {item.label}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </>
            )
          : (
          <>
            <Container size="ml">
              <Group className={classes.groupContainer}>
                <div>
                  <div className="footerheaders">Company</div>
                  <ul>
                    {urlSetOne.map((item, index) => (
                      <li key={index}>
                        <a
                          href={item.url}
                          target={item.target ? item.target : '_self'}
                        >
                          {item.label}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="">
                  <div className="footerheaders">Account</div>
                  <ul>
                    {urlSetTwo.map((item, index) => (
                      <li key={index}>
                        <a
                          href={item.url}
                          target={item.target ? item.target : '_self'}
                        >
                          {item.label}
                        </a>
                      </li>
                    ))}
                  </ul>
                  <div
                    className={
                      `${classes.footerMiddelHeaders}` + ' footerheaders'
                    }
                  >
                    Legal
                  </div>
                  <ul className={classes.footerUl}>
                    {urlSetLegal.map((item, index) => (
                      <li key={index}>
                        <a
                          href={item.url}
                          target={item.target ? item.target : '_self'}
                        >
                          {item.label}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="">
                  <div className="footerheaders">Popular Categories</div>
                  <ul>
                    {urlSetThree.map((item, index) => (
                      <li key={index}>
                        <a
                          href={item.url}
                          target={item.target ? item.target : '_self'}
                        >
                          {item.label}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="">
                  <div className="footerheaders">Download App</div>
                  {renderDownloadAppContent()}
                </div>
                <div className="">
                  <div className="right mobile-col-12">
                    <div className="footerheaders exceptional">
                      Customer Service
                    </div>
                    {renderNeedHelpButton()}
                    <ul className="customerServiceNewLinks">
                      {urlSetFive.map((item, index) => (
                        <li key={index}>
                          <a
                            href={item.url}
                            target={item.target ? item.target : '_self'}
                          >
                            {item.label}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </Group>
            </Container>
          </>
            )}

        <Container
          size="xl"
          pl="md"
          pr="md"
          pt="xl"
          pb={largeScreen ? '0px' : 'xl'}
        >
          <Group className={classes.acknowledgementGroup} noWrap>
            <Box w="50px" className={classes.acknLogo}>
              <img
                src="/content/themes/mydeal/images/acknowledgement-of-country.svg"
                alt="acknowledgement of country logo"
              />
            </Box>
            <ul className={classes.acknowledgementContent}>
              <li className={classes.acklist}>
                We acknowledge the Traditional Owners and Custodians of
                Country throughout Australia. We pay our respect to all First
                Nations peoples and acknowledge Elders past and present.
              </li>
              <li className={classes.acklist}>
                <a
                  href="https://www.woolworthsgroup.com.au/au/en/reconciliation/reconciliation-at-woolworths-group.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  <b>Read more about our commitment to reconciliation</b>
                </a>
              </li>
            </ul>
          </Group>
        </Container>
      </footer>
      <div className="copyright-holder">
        <Container size="xl" className={classes.copyrightContainer}>
          <Group className={classes.groupContainer}>
            <Box className={classes.copyrightLogo}>
              <a href="/">
                <img
                  className="footer-logo"
                  src="/content/themes/bliss/images/logo_footer.png"
                  alt="mydeal"
                />
              </a>
              <p className="copyright">
                Copyright {currentYear} MyDeal.com.au - © All rights reserved
              </p>
            </Box>
            <div className={classes.socialContainer}>
              <ul className="footer-social">
                {urlSocialMedia.map((item, index) => (
                  <li key={index}>
                    <a
                      className="social-icon"
                      href={item.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i
                        className={'fa fa-' + item.label}
                        aria-hidden="true"
                      ></i>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </Group>
        </Container>
      </div>
    </>
  );
};

export const FooterWrapped = (props: FooterProps): React.ReactElement => (
  <PartialWrapper>
    <Footer {...props} />
  </PartialWrapper>
);
