import { createStyles } from '@mantine/core';

export const useStyles = createStyles(theme => ({
  footerMiddelHeaders: {
    paddingBottom: '20px'
  },
  footerMobileAccordion: {
    width: '100%'
  },
  accordionItem: {
    backgroundColor: 'transparent',
    fontSize: '18px',
    color: '#fff',
    borderBottom: '1px solid #171717',
    '&[data-active]': {
      backgroundColor: 'transparent'
    }
  },

  accordionControl: {
    paddingTop: '7px',
    paddingBottom: '7px',
    backgroundColor: '#464646',
    '&:active': {
      borderColor: 'transparent',
      backgroundColor: '#464646'
    }
  },
  accordionPanel: {
    backgroundColor: '#2d2d2d'
  },

  itemTitle: {
    outlineOffset: '2px'
  },
  accordionLabel: {
    fontSize: '18px',
    color: '#fff',
    fontWeight: 'bold'
  },
  chevron: {
    fontSize: '18px',
    color: '#fff',
    svg: {
      width: '30px',
      height: '30px'
    }
  },
  acknowledgementGroup: {
    alignItems: 'flex-start'
  },
  acknLogo: {
    minWidth: '50px',
    paddingTop: '6px'
  },
  acknowledgementContent: {
    fontSize: '14px',
    lineHeight: '18.2px'
  },
  acklist: {
    fontSize: '14px',
    lineHeight: '18.2px'
  },
  groupContainer: {
    justifyContent: 'space-between',
    alignItems: 'flex-start'
  },
  copyrightContainer: {
    borderTop: '1px solid #363636',
    paddingTop: '30px',
    paddingBottom: '30px '
  },
  socialContainer: {
    [theme.fn.smallerThan('sm')]: {
      textAlign: 'center',
      width: '100%'
    }
  },
  copyrightLogo: { textAlign: 'center' },
  footerAppLink: {},
  appDescription: {
    maxWidth: '200px'
  },
  footerUl: {
    maxWidth: '211px'
  }
}));
